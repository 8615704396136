
import request from '@/utils/request'
export function area_listZone(params) {
  return request({
    url: '/area/data/listZone',
    method: 'get',
    params
  })
}

export function area_countRisk(params) { //区域博物馆文物风险占比
  return request({
    url: '/site/data/countRisk',
    method: 'get',
    params
  })
}
